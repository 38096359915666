/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// src/templates/gaPress.tsx
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../generated/graphql-types";
import { buildMenu, Layout } from "../layouts";
import { getCleanedRTF } from "@bond-london/graphcms-rich-text";
import { Hero } from "../components";
import { RTF } from "../elements";

import leftIcon from "../images/Left Button.svg";

type DataProps = {
  graphCmsGaPress: {
    articleTitle: string;
    createdAt: string;
    fullArticleLink: string;
  };
};

const GaPressPage: React.FC<
  PageProps<Pick<Query, "graphCmsGaPress" | "graphCmsSectorPage">>
> = ({
  path,
  data: { graphCmsGaPress: nodes, graphCmsSectorPage: sectorPage },
}) => {
  // const { articleTitle, createdAt, fullArticleLink } = data.graphCmsGaPress;
  let content;
  let content1;
  let images;
  let buttonlable;
  let buttonlink;
  let publishingDate;
  let sourceLink;
  let articleImage;
  const press_title = nodes?.articleTitle;
  content = getCleanedRTF(nodes?.articleContent);
  content1 = nodes?.articleContent.raw.children;
  articleImage = nodes?.thumbnail.url;
  buttonlable = nodes?.articleButtonLable;
  buttonlink = nodes?.articleButtonLink;
  publishingDate = nodes?.publishingDate;
  sourceLink = nodes?.sourceLink;
  console.log(nodes?.articleTitle);
  console.log(press_title);
  const formattedDate = new Date(publishingDate).toLocaleString("en-in", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const parts = formattedDate.split(" ");
  const monthInCamelCase =
    parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase();
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title={"Press Articles | Partners in Performance"}
      description={sectorPage?.description}
    >
      <Hero
        title={"Press Articles | " + press_title}
        image=""
        colour="MediumBlue"
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <section className="py-section relative page-grid z-10">
        <div className="row-start-1 md:row-start-1 md:row-span-1 col-start-2 col-span-10 md:col-start-2 md:col-span-12 xl:col-start-2 xl:col-span-3 w-auto xl:w-desktop-4-cols">
          <div className="">
            <img
              src={articleImage}
              className="w-full ml-auto  mt-10px xl:mt-auto md::mt-auto xl:ml-auto "
            ></img>
          </div>
        </div>
        <div className="row-start-2 xl:row-start-1 md:row-span-7 text-right-margin col-start-2 col-span-12 xl:col-start-6 xl:col-span-7 md:col-span-12 md:col-start-2 mt-20px md:mt-0 xl:mt-0">
          <div className="flex justify-between text-14 text-grey mb-5px">
            <p>{parts[0] + " " + monthInCamelCase + " " + parts[2]}</p>
          </div>
          <h3 className="h3 mt-20px md:mt-0">{press_title}</h3>
          <div className="p3 space-y-20px mt-20px space-y-20px md:space-y-40px">
            <RTF
              content={content1}
              className="col-start-2 col-span-6 text-right-margin mt-20px space-y-20px"
            />
            <br></br>
            {buttonlable.map((values, index) => {
              return (
                <button className="ga-card-button">
                  <a href={buttonlink[index]} target="_blank" rel="noreferrer">
                    {values}
                  </a>
                </button>
              );
            })}
          </div>
        </div>
      </section>
      <section className="pb-section pt-10px page-grid">
        <a
          href="/en/press"
          className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6 bg"
        >
          <img
            src={leftIcon}
            alt="Left"
            className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
          />
          Back to press
        </a>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($fullArticleLink: String!) {
    graphCmsGaPress(fullArticleLink: { eq: $fullArticleLink }) {
      articleTitle
      createdAt
      fullArticleLink
      articleContent {
        cleaned
        raw
      }
      articleTitle
      createdAt
      fullArticleLink
      gaAuthors {
        authorName
        authorLinks
      }
      sourceLink
      relatedRegions
      thumbnail {
        url
      }
      publishingDate
      articleButtonLable
      articleButtonLink
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;

export default GaPressPage;
